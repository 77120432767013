@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  margin: 0;
  padding: 0;
  font-size: min(2vw, 16px);
  height: 100%;
  width: 100%;
}

@media not screen and (hover) {
  * {
    cursor: default !important;
  }
}

#root {
  height: 100%;
  width: 100%;
}

* {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  @apply mobile:!cursor-default;
}

body {
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: #181328;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
}

h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  color: white;
}

h3 {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  text-align: left;
  opacity: 0.8;
}

p {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  color: white;
}

button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 31px;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  height: 62px;
  @apply transition-all duration-300;
}

.button-purple {
  background-color: #982fc6;
  font-weight: 500;
  line-height: 30px;
  @apply px-[60px];
}

.button-purple:hover {
  background-color: #38126b;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.card {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(50% - 27.5px);
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  box-sizing: border-box;
  background: rgba(15, 5, 37, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  border-radius: 20px;
  @apply px-5 pt-6 pb-8;
}

.card>div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 28px;
  min-height: 100px;
  position: relative;
}

.card>p {
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 0.8;
  color: white;
  @apply text-xl mobile:text-3xl;
}

.card>div>img {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 8px;
  top: 0px;
}

.card>div>p {
  justify-self: center;
  flex-grow: 1;
  margin-left: 143px;
  text-align: left;
}

.description {
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 0.8;
  @apply text-lg;
}

.card>div>div>p:first-child {
  justify-self: center;
  flex-grow: 1;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  margin-left: 143px;
  text-align: left;
  color: white;
  opacity: 1;
  @apply text-xl mobile:text-3xl;
}

.card>div>div>p {
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  margin-left: 143px;
  text-align: left;
  color: white;
  opacity: 0.8;
  @apply text-xl mobile:text-3xl;
}

.guarantee {
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

.tooltip {
  background-color: #271d4a;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.bg-noise2-purple {
  background: url("/public/assets/noise2.png") no-repeat, 0% 0% / 100px 100px repeat, #F3E8FF;
  background-blend-mode: soft-light, normal;
}

.bg-noise2-white {
  background: url("/public/assets/noise2.png") no-repeat, 0% 0% / 100px 100px, #f5f8fb;
  background-blend-mode: soft-light, normal;
}

.bg-noise2-gray {
  background: url("/public/assets/noise2.png") no-repeat, 0% 0% / 100px 100px repeat, #E2E8F0;
  background-blend-mode: soft-light, normal;
}

.faq {
  @apply relative border-b border-b-black;
}

.faq > div:first-child {
  position: relative;
  z-index: 100;
}

.faq>div:first-child>svg {
  transition: all 0.3s ease;
}

.faq.open > div:first-child > svg {
  transform: rotate(180deg);
}

.faq>div>div:not(:first-child) {
  transition: all 0.3s ease;
  @apply h-0 pb-0;
}

.faq.open>div>div:not(:first-child) {
  @apply h-[max-content] pb-6;
}

.faq > div:not(:first-child) {
  transition: all 0.3s ease;
  opacity: 0;
}

.faq.open>div:not(:first-child) {
  transition: all 0.3s ease;
  opacity: 1;
}

@keyframes marquee1 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes navigation {
  0% {
    transform: translate(-50%, 100%);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, -32px);
    opacity: 1;
  }
}

.animate-navbar {
  transform: translate(-50%, 100%);
  opacity: 0;
  animation: 0.3s navigation ease-in forwards;
  animation-delay: 2s;
}

.marquee > div:first-child {
  animation: marquee1 24s linear infinite;
  -webkit-animation: marquee1 24s 0.5s linear infinite;
}

.marquee > div:last-child {
  position: absolute;
  animation: marquee2 24s linear infinite;
  -webkit-animation: marquee2 24s 0.5s linear infinite;
}